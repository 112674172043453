<template>
  <div class="brandInformation">
    <header>{{detail.title}}</header>
    <!-- 背景图 -->
    <div class="content" v-html="detail.content"></div>
  </div>
</template>

<script>
import { articleDetail } from "@/api/index.js";
export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      detail:{}
    };
  },
  mounted() {
    this.articleDetail()
  },
  methods: {
    articleDetail() {
      articleDetail({
        id:this.$route.query.id
      }).then((res) => {
        if(res.code == 0){
          this.detail = res.data
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.brandInformation {
  width: 100%;
  header {
    width: 1200px;
    margin: 0 auto;
    font-size: 36px;
    font-weight: 700;
    color: #000;
    height: 120px;
    line-height: 120px;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
}
</style>
